<template>
  <div class="sign_in">
    <div class="return_icon">
      <i class="el-icon-arrow-left" @click="$router.go(-1)"></i>
    </div>
    <div class="sign_in_main">
      <div class="sign_in_title1">您好！</div>
      <div class="sign_in_title2">欢迎您参加面试</div>
      <div class="sign_in_title3">您的面试时间为</div>
      <div class="sign_in_title4">
        <template v-if="start_time&&end_time">
          <span>{{ time_format(start_time) }}</span>
          ~
          <span>{{ time_format(end_time) }}</span>
        </template>
        <template v-else>-</template>
      </div>
      <div class="sign_box">
        <!-- <div class="sign_btn" @click="$router.go(-1)">签到</div> -->
        <div class="sign_btn" @click="sign_in_btn">签到</div>
        <div class="sign_btn_btm">
          <i class="el-icon-location-outline"></i>
          您已进入可签到区域
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {signin} from '@/api/api';
export default {
  data() {
    return {
      order_id:this.$route.query.order_id,
      candidate_userid:this.$route.query.candidate_userid,
      start_time:this.$route.query.start_time,
      end_time:this.$route.query.end_time,
    };
  },
  mounted() {
    
  },
  methods: {
    async sign_in_btn(){
      // lat 30.584355
      // lng 114.298572
      const res = await signin({
        order_id:this.order_id,
        candidate_userid:this.candidate_userid,
        lat:"30.584355",
        lon:"114.298572",
      });
      if(res.data.code!=200){
        return this.msg("error",res.data.msg);
      }
      this.msg("success","签到成功");
      this.$router.go(-1);
    }
  },
};
</script>

<style scoped>
.sign_in {
  min-height: 100vh;
  height: 100%;
  box-sizing: border-box;
  padding: 1rem;
  background: #fff;
}
.return_icon {
  height: 2.7rem;
  line-height: 2.7rem;
}
.sign_in_main {
  box-sizing: border-box;
  padding: 0 2rem;
}
.sign_in_title1 {
  color: #5b5b5b;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 2.5rem;
}
.sign_in_title2 {
  color: #a9a9a9;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}
.sign_in_title3 {
  color: #838383;
  font-size: 0.875rem;
  margin-top: 5rem;
}
.sign_in_title4 {
  color: #333;
  font-size: 1rem;
  font-weight: 500;
  margin-top: 0.5rem;
}
.sign_box {
  margin-top: 5rem;
  text-align: center;
}
.sign_btn {
  display: inline-block;
  width: 6.25rem;
  height: 6.25rem;
  border-radius: 50%;
  text-align: center;
  line-height: 6.25rem;
  color: #fff;
  font-size: 1.5rem;
  background: linear-gradient(313deg, #18aef3 -66.5%, #0a76e2 83.19%);
}
.sign_btn_btm {
  color: #838383;
  font-size: 0.875rem;
  margin-top: 1.25rem;
}
.box_map {
  width: 100%;
  height: 300px;
  background: pink;
}

#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 800px;
}
</style>
